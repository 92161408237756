import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({name,content,adWordEvent,adWordEventHomePage,adWordEventContactUs}) => {
    return(
        <Helmet>
                <title>{name}</title>
                <meta name="description" content={content}/>
                

                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-806088806"></script>
                <script>{adWordEvent}</script>
                <script>{adWordEventHomePage}</script>
                <script>{adWordEventContactUs}</script>
        </Helmet>
    );
}
export default Meta;