import React from 'react';
import ProductsBody from './ProductsBody';
import FooterNav from '../../components/footernav/FooterNav';
import Footer from '../../components/footer/Footer';

import Meta from '../seo/Meta';
import Layout from '../layout/Layout';

const Products = () => {
    return(
        <>
            <Meta 
                name="Products | Viewmax" 
                content="Pan-African cinema platform for film distributors and cinema exhibitors across Africa"
            />
            <Layout>
                <div class="container">
                    <ProductsBody/>
                    <FooterNav/>
                    <Footer/>
                </div>
            </Layout>
        </>
    );
}

export default Products;