import React from 'react';
import './contactbody.css'

import { useForm } from '@formcarry/react';

const ContactBody = () => {
    // // Call the `useForm` hook in your function component
    // const {state, submit} = useForm({
    //   id: 'jlkOW_Dg478'
    // });
   
    // // Success message
    // if (state.submitted) {
    //   return window.location.href = "/sent";
    // }
    return (
        <section className="contact_form">
            <div className="row">
                <h1>Contact Us</h1>
                <h2><a href="https://wa.me/2349065800449">Tap to contact our Africa support team: +2349065800449(Whatsapp)</a></h2>
                <br/>
                <h2><a href="https://forms.gle/entzx2FeRSLM7gvR7">Tap to get direct email updates on all Viewmax services</a></h2>
                <br/>
                <br/>
                <p><u>Alternatively, use form below:</u></p>
                <br/>
                <br/>

                {/* email used for getform.io support@viewmax.com.ng  */}
                <form action="https://getform.io/f/RdGLPQeD" acceptCharset='UTF-8' method='POST'>
                    <label htmlFor="name">Name</label>
                    <input placeholder="Enter your Name" name="name" type="text" minLength="5" required/>

                    <label htmlFor="email">Email</label>
                    <input placeholder="Enter your Email" name="email" type="email" minLength="5" required/>

                    <label htmlFor="country">Country</label>
                    <input placeholder="Enter your Country" name="country" type="text" minLength="5" required/>

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input placeholder="Enter your Phone Number" name="phone" type="tel" minLength="5" required/>

                    <label htmlFor="subject">Choose Message Subject</label>
                        <select className="dropbtn" name="subject">
                            <option value="Viewmax Cinema License">Get Viewmax Cinema License</option>
                            <option value="Become a Viewmax Agent">Become a Viewmax Agent</option>
                            <option value="General Enquiry">General Enquiry</option>
                            <option value="Film Distributors License">Get Film Distributors License</option>
                            {/* <option value="Order a Product">Order a Product</option> */}
                        </select>

                    <label htmlFor="message">Enter Your Message</label>
                    <textarea placeholder="Message" name="message" minLength="5" required></textarea>
            
                    <button className="submit" type="submit" value="Send">Send</button>
                </form>
                {/* <form onSubmit={submit} acceptCharset='UTF-8' method='POST'>
                    <label htmlFor="name">Your Name</label>
                    <input placeholder="Enter your name" name="name" type="text" minLength="5" required/>

                    <label htmlFor="email">Your Email</label>
                    <input placeholder="Enter your email" name="email" type="email" minLength="5" required/>

                    <label htmlFor="country">Your Country</label>
                    <input placeholder="Enter your country" name="country" type="text" minLength="5" required/>

                    <label htmlFor="subject">Choose Message Subject</label>
                        <select className="dropbtn" name="subject">
                            <option value="Viewmax Cinema License">Get Viewmax Cinema License</option>
                            <option value="Film Distributors License">Get Film Distributors License</option>
                            <option value="General Enquiry">General Enquiry</option>
                            <option value="Order a Product">Order a Product</option>
                        </select>

                    <label htmlFor="message">Enter Your Message</label>
                    <textarea placeholder="Message" name="message" minLength="5" required></textarea>
            
                    <button className="submit" type="submit" value="Send">Send</button>
                </form> */}
            </div>
        </section>
    );
}


export default ContactBody;