import React from 'react';
import './footer.css';

const Footer = () => {
    return(
        <>
            <section className="footer">
            <div className="row">
                <div className="footer_content_1">
                    <p>The Viewmax logo and technologies are trademark properties of Viewmax Entertainment Network LLC and its
                        regional subsidiaries.
                        {/* The Viewmax logo and technologies are trademark properties of Viewmax 娱乐公司 LLC and its
                        regional subsidiaries. */}
                        {/* <br/>&#169; 2021 Viewmax 娱乐公司 LLC. All Rights Reserved. */}
                        <br/>&#169; 2021 Viewmax Entertainment Network LLC. 
                        <br/>All Rights Reserved.
                    </p>
                </div>
            </div>
            </section>
        </>
    );
}

export default Footer;

