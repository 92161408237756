import React from 'react';
import './productsbody.css';
import ViewmaxSystem from '../../images/viewmax_system.jpg'
import ViewmaxProjector from '../../images/viewmax-projector.png'
import ViewmaxProjector2 from '../../images/viewmax-projector2.png'



const ProductsBody = () => {
    return(
        <section class="products_listings">
            <h1>Products</h1>
            {/* <div class="products">
                <div class="row">
                    <div class="product_img">
                        <img class="" src={ViewmaxSystem} alt="viewmax technologies africa"/>
                    </div>
                    <div class="product_description">
                        <h2>Viewmax Digital Cinema Receiver System</h2>
                        <a class="contact_us" href="/contact">
                            <p>In Stock <button>Get License</button></p>
                        </a>
                        <ul>
                            <li>Issued under the "Viewmax Cinema License"</li>
                        </ul>
                    </div>
                </div>
            </div> */}

            <hr/>
            <div class="products">
                <div class="row">
                    <div class="product_img">
                        <img class="" src={ViewmaxProjector} alt="viewmax technologies africa"/>
                    </div>
                    <div class="product_description">
                        <h2>Viewmax T200 - Digital Projector <br/>(Cinema Edition)</h2>
                        <a class="contact_us" href="/contact">
                            <p>In Stock <button>Buy Now</button></p>
                        </a>
                        <ul>
                            <li>1080p Native Resolution, supports upto 4K Resolution</li>
                            <li>100" Maximum Screen Size</li>
                            <li>Energy Saving</li>
                            <li>Maintenance-Free</li>
                            <li>LED Technology</li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr/>
            <div class="products">
                <div class="row">
                    <div class="product_img">
                        <img class="" src={ViewmaxProjector2} alt="viewmax technologies africa"/>
                    </div>
                    <div class="product_description">
                        <h2>Viewmax T500 - Digital Projector <br/>(Cinema Edition)</h2>
                        <a class="contact_us" href="/contact">
                            <p>In Stock <button>Buy Now</button></p>
                        </a>
                        <ul>
                            <li>1080p Native Resolution, 4K Native Resolution</li>
                            <li>150" Maximum Screen Size</li>
                            <li>Energy Saving</li>
                            <li>Maintenance-Free</li>
                            <li>LED Technology</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductsBody;