import React from 'react';
import NavBar from '../navbar/Navbar';

export default function Layout({children}) {
  return(
      <>
        <NavBar/>
        {children}
      </>
  );
}
