import React from 'react';
import HomeBody from './HomeBody';
import FooterNav from '../footernav/FooterNav';
import Footer from '../footer/Footer';

import Meta from '../seo/Meta';
import Layout from '../layout/Layout';

const Home = () => {
    return(
        <>
            <Meta 
                name="Viewmax Entertainment | Africa" 
                content="Pan-African cinema platform for film distributors and cinema exhibitors across Africa"
                adWordEvent="window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-806088806');"
                adWordEventHomePage="gtag('event', 'conversion', {'send_to': 'AW-806088806/TORDCKn-nY4YEObgr4AD'});"
            />
                
            <Layout>
                <div className="container">
                    <HomeBody/>
                    <FooterNav/>
                    <Footer/>
                </div>
            </Layout>
        </>
    );
}

export default Home;