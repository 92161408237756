import React from 'react';
import './message_sent.css';


const MessageSentBody = () => {
    return(
        <div className="message_sent">
            <span id="message_1">Thank you! We received your submission.</span>
            <span id="message_2">Our representative will be in touch with you soon.</span>
        </div>
    );
}

export default MessageSentBody;