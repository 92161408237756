import React from 'react';
import './homebody.css';
import Logo from '../../images/whiteViewmax.svg';
import Africa from '../../images/africa.png';
import ViewmaxSystem from '../../images/viewmax_system.jpg';
import AppScreenshot from '../../images/app.png';
import ApproveImage from '../../images/approve.png';
import ArrowImage from '../../images/arrow.png';
import SetupImage from '../../images/setup.png';
import CinemaImage from '../../images/cinema.png';
import TestimonialImage1 from '../../images/Untitled-5.png';
import TestimonialImage2 from '../../images/Untitled-2.png';

const HomeBody = () => {
    return(
        <>
            <section className="banner">
                <div className='row'>
                <div className="banner_img">
                        <img src={Logo} alt="viewmax technologies africa"/>
                    </div>
                    <div className="banner_text">
                    <h1>Introducing Green and Sustainable Community Cinemas for Premium Entertainment across Africa</h1>
                        {/* <h1>Premium Entertainment Delivered To Your Cinema Audience in Comfort and Style across 54 African
                            Countries</h1> */}
                    </div>
                    <div>
                        <div className="banner_buttons">
                                <div> <a href="#bookmark_get_license"> <p>Get Viewmax</p> </a> </div> 
                                <div class="divider"></div>
                                <div> <a href="https://forms.gle/entzx2FeRSLM7gvR7"> <p>Get Email Updates</p> </a> </div>
                        </div>
                    </div>
                    {/* <div className="chinese">
                        <p>Viewmax 娱乐公司 | Africa</p>
                    </div> */}
                </div>
            </section>

            {/* <section className="viewmax_intro">
                <div className="row">
                    <div className="viewmax_intro_content_1">
                        <p>The most advanced content platform for cinemas</p>
                    </div>
                    <div className="viewmax_intro_content_2">
                        <p>Viewmax Entertainment brings premium movies and shows from within and beyond Africa directly to
                            your cinema anywhere across Africa.</p>
                    </div>
                </div>
            </section>

            <section className="access_movies_anywhere">
                <div className="row">
                    <div className="access_movies_anywhere_content_1">
                        <img src={Africa} alt="viewmax technologies africa"/>
                    </div>
                    <div className="access_movies_anywhere_content_2">
                        <h1>Access Movies From Anywhere</h1>
                        <p>Viewmax provides movies to cinemas located in any location across Africa.</p>
                        <p>Experience 4K movies from top distributors and producers in your cinema.</p>
                        <p>Let your audience experience the very best of African and foreign content from around the world.
                        </p>
                    </div>
                </div>
            </section>

            <section className="easy_system_setup">
                <div className="row">
                    <div className="easy_system_setup_content_1">
                        <h1>Easy System Setup</h1>
                        <p>Viewmax Cinema Systems can be easily installed and integrated into your new and existing cinemas.
                        </p>
                        <p>Connect your Audio/Visual equipment to the Viewmax Cinema System seamlessly. Offer your audience
                            the best of 4K movies with Surround Sound audio quality.</p>
                        <p>Fast installation and 24/7 technical support.</p>
                    </div>
                    <div className="easy_system_setup_content_2">
                        <img src={ViewmaxSystem} alt="viewmax technologies africa"/>
                    </div>
                </div>
            </section>

            <section className="in_app_experience">
                <div className="row">
                    <div className="in_app_experience_content_1">
                        <img src={AppScreenshot} alt="viewmax technologies africa"/>
                    </div>
                    <div className="in_app_experience_content_2">
                        <h1>Exciting App for ticketing and much more</h1>
                        <p>Movie ticketing and booking experience just got simpler on the Viewmax App.</p>
                        <p>Your customers can easily find your cinema's location on the Viewmax app to book and buy movie
                            tickets on our app convieniently. Anywhere. Anytime.</p>
                    </div>
                </div>
            </section>


            <section className="onboard_cinema">
                <div className="row">
                    <div className="onboard_cinema_content_1">
                        <p>Onboard Your Cinema</p>
                    </div>
                </div>

                <div className="row">
                    <div className="onboard_cinema_content_2">
                        <img className="onboard_cinema_icon" src={ApproveImage} alt="viewmax technologies africa"/>
                        <p>Get Viewmax License</p>
                    </div>
                    <div className="arrow">
                        <img className="onboard_cinema_icon_arrow" src={ArrowImage} alt="viewmax technologies africa"/>
                    </div>
                    <div className="onboard_cinema_content_3">
                        <img className="onboard_cinema_icon" src={SetupImage} alt="viewmax technologies africa"/>
                        <p> Viewmax Installed <br/>In Your Cinema</p>
                    </div>
                    <div className="arrow">
                        <img className="onboard_cinema_icon_arrow" src={ArrowImage} alt="viewmax technologies africa"/>
                    </div>
                    <div className="onboard_cinema_content_4">
                        <img className="onboard_cinema_icon" src={CinemaImage} alt="viewmax technologies africa"/>
                        <p>Launch</p>
                    </div>
                </div>
            </section>

            <section className="requirements">
                <div className="row">
                    <div className="requirements_content_1">
                        <h1>Requirements</h1>
                        <p><i className="fas fa-caret-right" aria-hidden="true"></i> Community cinema is advised to be within customers
                            walking distance for convienience.</p>
                        <p><i className="fas fa-caret-right" aria-hidden="true"></i> Community cinema should have a minimum of
                            50-seats capacity.</p>
                        <p><i className="fas fa-caret-right" aria-hidden="true"></i> Cinema may be located in estates, tertiary
                            institutions or residential communities.</p>
                        <p><i className="fas fa-caret-right" aria-hidden="true"></i> Sound and Visual (Projector) Equipment
                            installed in cinema.</p>
                    </div>
                </div>
            </section>

            <section className="explore">
                <div className="row">
                    <div className="explore_content" id="bookmark_get_license">
                        <h1>Explore Possibilities</h1>
                        <h3>Power your cinema with Viewmax digital systems today to enjoy the very best of African
                            Entertainment in your cinema. We eliminate the challenges associated with lauching and running
                            your
                            successful cinema brand anywhere across Africa.</h3>
                        <a href="/contact">
                            <p>Contact Us</p>
                        </a>
                    </div>
                </div>
            </section>

            <section className="get_cinema_license">
                <div className="row">
                    <div className="get_cinema_license_content_1">
                        <p>Get VIEWMAX Today</p>
                    </div>
                    <div className="get_cinema_license_content_2">
                        <a href="/contact">
                            <p>Get Viewmax Cinema License</p>
                        </a>
                    </div>
                    <div className="get_cinema_license_content_3">
                        <p>Available across Africa</p>
                    </div>
                    <div className="get_cinema_license_content_4">
                        <p id="bookmark_get_license">* Continent-Wide Delivery</p>
                        <p>* Viewmax Receiver Unit Provided Under License</p>
                    </div>
                </div>
            </section> */}

            <section className="testimony">
                <div className="row">
                    <div className="testimony_content_1">
                        {/* <img className="testimony images" src={TestimonialImage1} alt="viewmax technologies africa"/> */}

                        <p>"Thanks to Viewmax, we now have a successful cinema in our community.</p>

                        {/* <p>"Viewmax helped me realize my dream of running a successful cinema in my community. I do not
                            worry about giving my cutomers the best quality movie
                            experience."</p> */}
                        <p id="testimony_names">-Peter Linu</p>
                        <p id="testimony_country">South Africa</p>
                    </div>
                    <div className="testimony_content_2">
                        {/* <img className="testimony images" src={TestimonialImage2} alt="viewmax technologies africa"/> */}
                        <p>"Thanks to Viewmax, our community now enjoys a successful cinema that brings people together and provides an exceptional movie experience. The support and technology they offer have truly transformed our local entertainment options."</p>
                        {/* <p>"My biggest worry was not having my cinema seats filled.
                            Now I have no worries attracting new audiences and selling
                            more tickets thanks to Viewmax"</p> */}
                        {/* <p>"My biggest worry was not having my cinema seats filled.
                            Now I have no worries attracting new audiences and selling
                            more tickets thanks to Viewmax"</p> */}
                        <p id="testimony_names">-Musa Abdullahi </p>
                        <p id="testimony_country">Nigeria </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeBody;