import React from 'react';
import './privacy.css';

const PrivacyBody = () => {
    return(
        <section class="privacy">
            <h1>Privacy Policy</h1>
            <div class="row">
                <div class="PrivacyPolicyText">
                    <p>
                    <em>Information Collection and Use –</em> The only personal information that <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span></span> collects about you when you use Viewmax Official Websites is what you tell us about yourself or information you provide to us through the Contact Us section or when you send us an e-mail.
                    </p>
                    <p>
                    In line industry best practices, however, we do track usage patterns on an anonymous basis. Your identity cannot reasonably be ascertained from this information. Each time you visit our site, our server records your Internet Service Provider, the date and time of your visit, the pages you visit and the documents downloaded, the searches you performed, and the referring URLs you came from. We use this non-personal information to optimize our service.
                    </p>

                    <p>
                    For your convenience, we may offer links from our site to other websites. However, <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span> is not responsible for, nor do we accept any liability for, the personal information collection, use and disclosure practices of other entities to which our site may link. You should consult the privacy policies of these other entities before providing any personal information on their sites.
                    </p>
                    
                    
                    <p>
                    <em>Your Consent –</em> We collect, use and disclose your personal information with your consent or as permitted or required by law. Your consent may be express or implied, depending on the circumstances and the sensitivity of the personal information in question. If you provide personal information about another person to us, we assume you have the consent of that individual to enable us to collect, use or disclose their information as described in this policy. If you choose to provide personal information to <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span>, we assume that you consent to the collection, use and disclosure of your personal information as outlined in this privacy statement.
                    If you do not consent, please do not provide your personal information or, where applicable, exercise the opt-in or opt-out options offered. Subject to legal and contractual requirements and reasonable notice, you may refuse to consent to our collection, use and disclosure of your personal information or you may withdraw your consent to our further collection, use and disclosure of information about you, by contacting us.
                    </p>

                    <p>
                    <em>Security –</em> <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span> has implemented reasonable physical, organizational and technological security measures to safeguard the personal information in our custody or control. These include safeguards to protect personal information against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification.
                    </p>

                    <p>
                    <em>Changes to This Statement –</em> <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span> reserves the right to change this privacy statement at any time and we will take reasonable steps to advise affected individuals of any material changes, if necessary. However, you should review this privacy statement from time to time to ensure that you are aware of and accept any changes made. You may reach us to obtain a current copy of this privacy statement.
                    </p>

                    <p>
                    <em>Registration –</em> No registration is required to view the \viewmax Official Websites. However, if you choose to register or provide personal information on our newsletter sign-up, feedback forms, affiliate, partner and job application pages or other areas of our site, we will ask for information which may include your name, email address, company name, and phone number.
                    </p>

                    

                    <p>
                    <em>Email Alerts –</em> If a user wishes to subscribe to our alerts, updates, briefings etc. We ask for contact and delivery information such as name and e-mail address. All of these e-mail lists provide opt-out facilities online and with each e-mail broadcast. In some cases, the user is asked to provide optional demographic and personal interest information that is used to keep our content as relevant as possible.
                    </p>

                    
                    <p>
                    <em>Sharing –</em> We will share aggregated demographic information with our partners and advertisers. This is not linked to any personal information that can identify any individual person.
                    </p>

                    
                    <p>
                    <em>Log Files –</em> We use IP addresses to analyze trends, administer the site, track a user’s movement, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information.
                    </p>

                    
                    <p>
                    <em>Links –</em> Some of our websites contain links to other sites. Please be aware that <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span> is not responsible for the privacy practices of other sites. This privacy statement applies solely to information collected by this website.
                    </p>

                    
                    <p>
                    <em>Surveys and Contests –</em> From time to time, our site requests information from users via surveys or contests. Participation in these surveys or contests is completely voluntary and the user therefore has a choice whether or not to disclose this information. Information requested may include contact information (such as name and shipping address) and demographic information (such as sex and age). Contact information will be used to notify the winners and award prizes. Survey information will be used for purposes of monitoring or improving the use and satisfaction of this site.
                    </p>

                    

                    <br/>

                    <p style={{textAlign: 'center', fontWeight:'bold', fontSize:'22px'}}>We Use Cookies</p>
                    <p>
                    <br/>
                    <span style={{fontWeight: 'bold'}}>In compliance with the GDPR and to ensure that every user of our site understands that we use Cookies, we resolved to update the privacy policy by adding:</span>
                    <br/>
                    <br/>

                    1.   Readers should know that we collect information when you access content, advertising, sites, interactive widgets, applications, and other products (both on and off of our Services) where Viewmax Entertainment’s data collection technologies (such as web beacons, development tools, cookies and other technologies, etc.) are present. These data collection technologies allow us to understand your activity on and off our Services and to collect and store information when you interact with Services we offer to partners.

                    <br/><br/>

                    2.   This information also includes the kind of content or ads served, viewed or clicked on; the frequency and duration of your activities; the sites or apps you used before accessing our Services and where you went next; whether you engaged with specific content or ads; and whether you went on to visit an advertiser’s website, downloaded an advertiser’s app, purchased a product or service advertised, or took other actions.
                    <br/><br/>
                    We may collect information about you when we receive it from other users, third-parties, and affiliates, such as:
                    <br/><br/>
                    i) When you connect your account to third-party services or sign in using a third-party partner (like Facebook or Twitter).
                    <br/><br/>
                    ii) From publicly-available sources.
                    <br/><br/>
                    iii) From advertisers about your experiences or interactions with their offerings.
                    <br/><br/>
                    iv) We honor the choices customers have made about the uses of this information when we receive and use this data.

                    
                    </p>
                    <p>
                    <em>Special Offers –</em> New members may receive a welcome e-mail to verify the password and username. Established members will occasionally receive information on products, services, special deals, and a newsletter. Where this is the case, we present the option to not receive these types of communications at the point of sign-up.
                    </p>

                    
                    <p>
                    <em>Site and Service Updates –</em> We also send the user site and service announcement updates. Members are not able to unsubscribe from service announcements, which contain important information about the service. We communicate with the user to provide requested services and in regards to issues relating to their account via e-mail or phone.
                    </p>
                    
                    <p>
                    <em>Correction/Updating Personal Information –</em> If a user’s personally identifiable information changes, or if a user no longer desires our service, we will endeavor to provide a way to correct, update or remove that user’s personal data provided to us. This can be done at the registration page or by e-mailing the site editor. Many of our sites will offer subscribers an account management facility that will allow them to change or amend their own records.
                    </p>
                    
                    <p>
                    <em>Choice/Opt-Out –</em> Our users are given the opportunity to “opt-out” of having their information used for purposes not directly related to our site at the point where we ask for the information. Users who no longer wish to receive a newsletter \ alert \ briefing may opt-out of receiving these communications by replying to the unsubscribe link in the e-mail.
                    </p>

                    
                    <p>
                    <em>Notification of Changes –</em> If we decide to change our privacy policy, we will seek to advise all users of these changes.
                    </p>
                    
                    <p>
                    <em>Legal Disclaimer –</em> Though <span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}><span style={{fontWeight:'bold'}}>Viewmax Entertainment LLC</span></span></span> make every effort to preserve your privacy, we may need to disclose personal information when required by law, or in the good-faith belief that such action is necessary in order to conform to the edicts of the law or comply with a legal process served on our website.
                    </p>
                </div>
            </div>

       
        </section>
    );
}

export default PrivacyBody;