import React from 'react';
import ContactBody from './ContactBody';
import FooterNav from '../../components/footernav/FooterNav';
import Footer from '../../components/footer/Footer';

import Meta from '../seo/Meta';
import Layout from '../layout/Layout';


const Contact = () => {
    return(
        <>
            <Meta 
                name="Contact Us | Viewmax" 
                content="Contact Viewmax Official Support Helpdesk"
                adWordEvent="window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-806088806');"
                adWordEventContactUs="gtag('event', 'conversion', {'send_to': 'AW-806088806/1DgdCJaBs44YEObgr4AD'});"
            />
        <Layout>
            <div className="container">
                <ContactBody/>
                <FooterNav/>
                <Footer/>
            </div>
        </Layout>
        </>
    );
}

export default Contact;