import React from 'react';
import './footer_nav.css';
import Logo from '../../images/blackViewmax.svg';

const FooterNav = () => {
    return(
        <section className="footer_nav">
            <div className="row">
                <div className="footer_nav_items_img">
                    <img className="logo" src={Logo} alt="viewmax technologies africa"/>
                </div>
                <div className="footer_nav_items">
                    <h3>Viewmax</h3>
                    <p><a href="/products">Products</a></p>
                    <p><a href="/contact">Film Distributors</a></p>
                    <p><a href="/contact">Licensing</a></p>
                    <p><a href="/contact">Alerts and Updates</a></p>
                </div>
                <div className="footer_nav_items">
                    <h3>Help</h3>
                    <p><a href="/contact">FAQs</a></p>
                    <p><a href="/contact">Contact Us</a></p>
                </div>
                <div className="footer_nav_items">
                    <h3>Legal</h3>
                    <p><a href="/privacy">Privacy Policy</a></p>
                </div>
            </div>
        </section>
    );
}

export default FooterNav;