import React from 'react';
import './App.css';

import Home from '../home/Home';
import Products from '../products/products';
import Contact from '../contact/contact';
import Privacy from '../privacy/privacy';
import MessageSent from '../messagesent/messagesent';
import {Routes, Route} from 'react-router-dom';


function App() {
    return(
    <div>
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/products' element={<Products/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/privacy' element={<Privacy/>} />
            <Route path='/sent' element={<MessageSent/>} />
        </Routes>
    </div>
    );
}

export default App;
