import React from 'react';
import PrivacyBody from './PrivacyBody';
import FooterNav from '../../components/footernav/FooterNav';
import Footer from '../../components/footer/Footer';

import Meta from '../seo/Meta';

import Layout from '../layout/Layout';

const Privacy = () => {
    return(
        <>
            <Meta 
                name="Privacy Policy | Viewmax" 
                content="Pan-African cinema platform for film distributors and cinema exhibitors across Africa"
            />
            <Layout>
                <div class="container">
                    <PrivacyBody/>
                    <FooterNav/>
                    <Footer/>
                </div>
            </Layout>
    </>
    );
}

export default Privacy;