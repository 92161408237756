import React from 'react';
import FooterNav from '../footernav/FooterNav';
import Footer from '../footer/Footer';

import Meta from '../seo/Meta';
import Layout from '../layout/Layout';
import MessageSentBody from './messageSentBody';


const MessageSent = () => {
    return(
        <>
            <Meta 
                name="Sent | Viewmax" 
                content="Message Sent - Official Support Helpdesk"
                noindex="noindex"
                adWordEvent="window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-806088806');"
                adWordEventContactUs="gtag('event', 'conversion', {'send_to': 'AW-806088806/bMI9CLWdt44YEObgr4AD'});"
                />
        <Layout>
            <div className="container">
                <MessageSentBody/>
                <FooterNav/>
                <Footer/>
            </div>
        </Layout>
        </>
    );
}

export default MessageSent;