import React from 'react';
import './navbar.css';
import Logo from '../../images/blackViewmax.svg'

const contact = "/contact";
const products = "/products";
        
const myFunction = () => {
            document.getElementById("myDropdown").classList.toggle("show");
            document.getElementById("dropdown").classList.toggle("change");
        }

const Navbar = () => {
    return(
        <header>
            <a className="logo" href="/"><img className="logo" src={Logo}
                    alt="viewmax technologies africa"/></a>
            <nav>
                <ul className="nav__links">
                    <li><a href={contact}>Film Distributors</a></li>
                    <li><a href={products}>Products</a></li>
                    <li><a href={contact}>FAQ</a></li>
                    <li><a href="https://forms.gle/entzx2FeRSLM7gvR7">Get Email Updates</a></li>
                    
                </ul>
            </nav>

            <a className="contact_us" href={contact}><button className="navbar_button">Contact</button></a>

            <span id="dropdown" className="dropdown" onClick={myFunction}>
                <div className="tri_bars bar1"></div>
                <div className="tri_bars bar2"></div>
                <div className="tri_bars bar3"></div>
            </span>
            <div id="myDropdown" className="mobile__navlinks">
                <a href={contact}>Film Distributors</a>
                <a href={products}>Our Products</a>
                <a href="https://forms.gle/entzx2FeRSLM7gvR7">Get Email Updates</a>
            </div>
        </header>
    );
}

export default Navbar;